exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-document-library-tsx": () => import("./../../../src/pages/document-library.tsx" /* webpackChunkName: "component---src-pages-document-library-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pensions-in-payment-tsx": () => import("./../../../src/pages/pensions-in-payment.tsx" /* webpackChunkName: "component---src-pages-pensions-in-payment-tsx" */),
  "component---src-pages-your-benefits-tsx": () => import("./../../../src/pages/your-benefits.tsx" /* webpackChunkName: "component---src-pages-your-benefits-tsx" */)
}

